<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <material-card color="green" title="Propagandas">
          <v-btn color="primary" text @click="dialogCriar = true">Criar</v-btn>
          <v-data-table :headers="headers" :items="items">
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template slot="items" slot-scope="{ item }">
              <td>{{ item.nome }}</td>
              <td v-if="item.url" id="img_promo"><img id="img_promo" :src="item.url"></td>
              <td v-else  id="img_promo">Sem imagem</td>
              <td>{{ (item.begin).substring(0,10) }}</td>
              <td>{{ (item.end).substring(0,10) }}</td>

              <v-btn color="#000" @click="showProduto(item)">Ver</v-btn>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>Propaganda atualizado com sucesso!</div>
        <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>
          Verifique os dados e tente novamente
          <br />Verifique a conexão com a internet!
        </div>
        <v-icon size="16" @click="snackbarerror = false">mdi-close-circle</v-icon>
      </v-snackbar>
    </v-layout>

    <v-dialog v-model="dialog" width="900">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Informações sobre a Propaganda</v-card-title>
        <v-card-text v-if="selecionado">
          Nome:
          <v-text-field v-model="selecionado.nome"></v-text-field>
        </v-card-text>

        <v-card-text v-if="selecionado">
          Descrição:
          <v-text-field v-model="selecionado.descricao"></v-text-field>
        </v-card-text>
        <v-card-text v-if="selecionado">
          URL:
          <v-text-field v-model="selecionado.url"></v-text-field>
        </v-card-text>
        <v-card-text v-if="selecionado">
          Início:
          <v-text-field v-model="selecionado.begin"></v-text-field>
        </v-card-text>
        <v-card-text v-if="selecionado">
          Fim:
          <v-text-field v-model="selecionado.end"></v-text-field>
        </v-card-text>
        <v-date-picker v-if="selecionado" v-model="selecionado.end"></v-date-picker>
        <v-card-text v-if="selecionado && selecionado.ativa">
          Ativo:
          <p v-if="selecionado.ativa === true">Ativo</p>
          <p v-else>Desativado</p>
        </v-card-text>

        <input class="ml-3" type="file" id="file" ref="file" v-on:change="handleFileUpload()" />
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="cancelarPropaganda()">Excluir Propaganda</v-btn>
          <v-btn color="primary" text @click="atualizar()">Atualizar</v-btn>
          <v-btn color="primary" text @click="fecharDialog()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCriar" width="900">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Criar Propaganda</v-card-title>
        <v-card-text>
          Nome:
          <v-text-field v-model="criar.nome"></v-text-field>
        </v-card-text>
        <v-card-text>
          Descrição:
          <v-text-field v-model="criar.descricao"></v-text-field>
        </v-card-text>

        <v-card-text>
          URL:
          <v-text-field v-model="criar.url"></v-text-field>
        </v-card-text>
        <v-row justify="center">
          <v-card-text>Início:</v-card-text>

          <v-date-picker v-model="criar.begin" class="ml-3" dark></v-date-picker>
        </v-row>
        <v-row justify="center">
          <v-card-text>Fim:</v-card-text>
          <v-date-picker v-model="criar.end" class="ml-3" dark></v-date-picker>
        </v-row>
        <br />

        <input class="ml-3 mt-4" type="file" id="file" ref="file" v-on:change="handleFileUpload()" />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="criarPropagandas()">Criar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogeditar: false,
    dialogCriar: false,
    codigoDeBarras: "",
    headers_prod: [
      {
        text: "Nome",
        sortable: false,
        value: "nome"
      },
      { text: "Código de Barras", sortable: false, value: "codigoDeBarras" }
      // { text: "Actions", value: "actions", sortable: false }
    ],
    file: "",
    headers: [
      {
        sortable: false,
        text: "Nome",
        value: "nome"
      },
      {
        sortable: false,
        text: "Banner",
        value: "url"
      },
      {
        sortable: false,
        text: `Porcentagem`,
        align: "center",
        value: "porcentem"
      },
      {
        sortable: false,
        text: "Início",
        value: "begin"
      },
      {
        sortable: false,
        text: "Fim",
        value: "end"
      }
    ],
    snackbar: false,
    codigoDeBarras: null,
    snackbarerror: false,
    categorias: [],
    criar: {
      nome: "",
      descricao: "",
      url: '',
      ativa: true,
      begin: new Date().toISOString().substring(0, 10),
      end: new Date().toISOString().substring(0, 10)
    },
    select: null,
    selecionado: null,
    items: [],
    bottom: false,
    top: false,
    left: false,
    right: false
  }),
  beforeMount() {
    this.getPropagandas();
  },
  methods: {
    getPropagandas() {
      this.$http
        .get("/propagandas")
        .then(res => {
          let propagandas = res.data;

          for (let e of propagandas) {
            if (e.image && e.image.key)
              e.url = `http://api-store.emporiodasmarcas.com.br/files/propagandas/${e.image.key}`;
          }

          // console.log(propagandas);

          this.items = propagandas;
        })
        .catch(err => {
          if(err.error) alert(err.error)
            
        });
    },
    atualizar() {
      if (this.selecionado) {
        let dados = new FormData();
        dados.append("nome", this.selecionado.nome);
        dados.append("descricao", this.selecionado.descricao);
        dados.append("url", this.selecionado.url);
        dados.append("begin", this.selecionado.begin);
        dados.append("end", this.selecionado.end);

        if (this.file) dados.append("file", this.file);

        this.$http
          .put(`/propagandas/${this.selecionado._id}`, dados)
          .then(res => {
            this.snackbar = true;
          })
          .catch(err => {
            // console.log(err.error);

            this.snackbarerror = true;
          });
      }
    },
    cancelarPropaganda() {
      this.$http
        .delete(`/propagandas/${this.selecionado._id}`)
        .then(() => {
          this.snackbar = true;
          this.dialog = false;
          this.selecionado = null;
          this.$forceUpdate();
        })
        .catch(err => {});
    },
    showProduto(item) {
      this.selecionado = null;
      this.$http
        .get(`/propagandas/${item._id}`)
        .then(res => {
          // console.log(res);

          this.selecionado = res.data;
          this.dialog = true;
        })
        .catch(err => {
          if(err.error) alert(err.error)
            
        });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    fecharDialog() {
      this.dialog = false;
      this.selecionado = null;
      this.file = "";
    },
    criarPropagandas() {
      let dados_user = JSON.parse(localStorage.getItem("user"));

      let dados = new FormData();
      dados.append("nome", this.criar.nome);
      dados.append("descricao", this.criar.descricao);
      dados.append("url", this.criar.porcentagem);
      dados.append("begin", this.criar.begin);
      dados.append("end", this.criar.end);
      dados.append("createdBy", dados_user._id);

      if (this.file) dados.append("file", this.file);

      this.$http
        .post(`/propagandas`, dados)
        .then(() => {
          this.snackbar = true;
          this.dialogCriar = false;
          this.file = "";
          this.criar.nome = "";
          this.criar.descricao = "";
          this.criar.url = 0;
          this.criar.ativa = true;
          this.criar.begin = "";
          this.criar.end = "";
          this.$forceUpdate();
        })
        .catch(() => {
          this.snackbarerror = true;
        });
    }
  }
};
</script>

<style>
#img_promo{
  width: 100px;
  object-fit: contain;
  height: 100px;
}
</style>